var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

(function () {
  // save these original methods before they are overwritten
  var proto_initIcon = L.Marker.prototype._initIcon;
  var proto_setPos = L.Marker.prototype._setPos;
  var oldIE = L.DomUtil.TRANSFORM === "msTransform";
  L.Marker.addInitHook(function () {
    var iconOptions = (this || _global).options.icon && (this || _global).options.icon.options;
    var iconAnchor = iconOptions && (this || _global).options.icon.options.iconAnchor;

    if (iconAnchor) {
      iconAnchor = iconAnchor[0] + "px " + iconAnchor[1] + "px";
    }

    (this || _global).options.rotationOrigin = (this || _global).options.rotationOrigin || iconAnchor || "center bottom";
    (this || _global).options.rotationAngle = (this || _global).options.rotationAngle || 0; // Ensure marker keeps rotated during dragging

    this.on("drag", function (e) {
      e.target._applyRotation();
    });
  });
  L.Marker.include({
    _initIcon: function () {
      proto_initIcon.call(this || _global);
    },
    _setPos: function (pos) {
      proto_setPos.call(this || _global, pos);

      this._applyRotation();
    },
    _applyRotation: function () {
      if ((this || _global).options.rotationAngle) {
        (this || _global)._icon.style[L.DomUtil.TRANSFORM + "Origin"] = (this || _global).options.rotationOrigin;

        if (oldIE) {
          // for IE 9, use the 2D rotation
          (this || _global)._icon.style[L.DomUtil.TRANSFORM] = "rotate(" + (this || _global).options.rotationAngle + "deg)";
        } else {
          // for modern browsers, prefer the 3D accelerated version
          (this || _global)._icon.style[L.DomUtil.TRANSFORM] += " rotateZ(" + (this || _global).options.rotationAngle + "deg)";
        }
      }
    },
    setRotationAngle: function (angle) {
      (this || _global).options.rotationAngle = angle;
      this.update();
      return this || _global;
    },
    setRotationOrigin: function (origin) {
      (this || _global).options.rotationOrigin = origin;
      this.update();
      return this || _global;
    }
  });
})();

export default {};